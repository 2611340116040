import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "add-staff-booking"
    }}>{`Add Staff Booking`}</h1>
    <p>{`Let's explore how to add a staff booking for members.`}</p>
    <h2 {...{
      "id": "booking-members-into-events"
    }}>{`Booking Members into Events`}</h2>
    <p>{`Your calendar URL will be provided to you by a member of your organisation. It's usually structured as events.mylibrary.com, and the staff portal can be found by appending a /staff to the end, for example: events.mylibrary.com`}</p>
    <ol>
      <li parentName="ol">{`Select the event`}</li>
      <li parentName="ol">{`Under 'Add Bookings' add the relevant information, all fields with * are compulsory`}</li>
      <li parentName="ol">{`Enter the members 'First name', 'Last Name', 'Age' if relevant`}</li>
      <li parentName="ol">{`Enter the 'Library Card Number', if an email is attached to the LMS record, a green envelope will appear`}</li>
      <li parentName="ol">{`Hover over the envelope to check the details`}</li>
      <li parentName="ol">{`If a red 'X' appears no card number is found on the LMS, check their details`}</li>
      <li parentName="ol">{`Add relevant 'Booking Notes' including but not limited to dietary information, requirements etc and Staff name`}</li>
      <li parentName="ol">{`Either click 'Add Booking' or the grey person with a plus icon to add additional people to the booking`}</li>
      <li parentName="ol">{`Click 'Confirm Booking'`}</li>
      <li parentName="ol">{`Click 'Close'`}</li>
      <li parentName="ol">{`The booking will now appear under Current Bookings`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/Tf5Hq7B8rY4" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">Use the magic wand to add the same booking to multiple events.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      